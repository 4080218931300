.multi .MuiTab-wrapper {
  text-align: left !important;
  flex-direction: unset;
  justify-content: unset;
  padding-left: 10px;
  padding-right: 10px;
}

.js-plotly-plot .plotly .cursor-w-resize {
  pointer-events: none !important;
}
.js-plotly-plot .plotly .cursor-e-resize {
  pointer-events: none !important;
}
