.eeg .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom-color: #002f42 !important;
  border-bottom-width: 1.5px !important;
}

.eeg .MuiInput-underline:before {
  transition: unset !important;
}

.eeg .MuiSelect-select.MuiSelect-select {
  background-color: white !important;
  font-size: 14px;
  padding-bottom: 4px;
  padding-left: 0px;
}

.eeg .MuiMenuItem-root {
  font-size: 14px !important;
  line-height: 16px !important;
}

.eeg .MuiFormHelperText-root {
  font-size: x-small !important;
}

.eeg .MuiMenu-list {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.eeg .MuiSelect-icon {
  font-size: 1.35rem;
}
